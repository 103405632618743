import styled, { css } from 'styled-components';
import { GREYS, FUNCTIONAL, SHADOW } from 'UI/globals/colours';

export const MStyles = {
  modal: {
    padding: '24px 24px 24px 24px',
    maxWidth: '400px',
    width: '100%',
    borderRadius: '4px',
    margin: '40px auto 0px auto',
    boxShadow: SHADOW,
    border: '1px solid ' + GREYS.silver,
    backgroundColor: GREYS.black,
    color: GREYS.silver,
  },
};

export const GreenIcon = styled.i`
  display: block;
  color: ${FUNCTIONAL.positive};
  font-size: 48px;
  text-align: center;
  margin-bottom: 16px;
`;

export const ModalIcon = styled.i`
  display: block;
  font-size: 48px;
  text-align: center;
  margin-bottom: 16px;
`;

export const ModalTotal = styled.p`
  color: ${FUNCTIONAL.positive};
  font-size: 14px;
  font-weight: 800;
  text-align: center;
  margin-bottom: 16px;
`;

export const MContentContainer = styled.div``;

export const MContentTitle = styled.h1``;

export const MContentSubtitle = styled.p``;

export const MContentLink = styled.a``;

export const Total = styled.div`
  color: ${FUNCTIONAL.positive};
  font-size: 14px;
  font-weight: 800;
  text-align: center;
  margin-bottom: 16px;
  ${({ error }) =>
    error &&
    css`
      color: ${FUNCTIONAL.negative};
    `}
`;

export const InfoText = styled.p`
  color: ${GREYS.black};
  margin-bottom: 16px;
  text-align: center;
`;

export const SeeDetails = styled.button`
  display: block;
  margin: 0 auto;
  color: ${GREYS.dove};
  margin-bottom: 16px;
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 14px;
  font-weight: normal;
`;

export const Separator = styled.hr`
  border: none;
  border-top: 1px solid ${GREYS.dove};
  margin: 0 0 16px 0;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  margin-bottom: 16px;
`;

export const Amount = styled.div`
  font-weight: 700;
  font-size: 14px;
`;

export const IC = styled.div`
  width: 100%;
`;

export const CrossIcon = styled.i`
  display: block;
  color: ${FUNCTIONAL.negative};
  font-size: 48px;
  text-align: center;
  margin-bottom: 16px;
`;
