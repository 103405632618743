import { MD, QH6 } from 'CORE__UI/apps/QuickDepositApp/core__quickDeposit';
import styled from 'styled-components';
import { FONT, GREYS, SHADOW } from '../../globals/colours';

const MDJH = styled(MD)`
  color: ${FONT.primary};
  font-size: 18px;
  font-weight: 700;
  text-transform: capitalize;
`;

const MStyles = {
  modal: {
    padding: '24px 24px 24px 24px',
    maxWidth: '400px',
    width: '100%',
    borderRadius: '4px',
    margin: '40px auto 0px auto',
    boxShadow: SHADOW,
    border: '1px solid ' + GREYS.silver,
    backgroundColor: GREYS.silverD,
  },
};
export { MDJH as MD, MStyles, QH6 };
