import styled from 'styled-components';
import { GREYS, FONT } from 'UI/globals/colours';

export const MStyles = {
  modal: {
    padding: '24px 24px 24px 24px',
    maxWidth: '400px',
    width: '100%',
    borderRadius: '4px',
    marginTop: '60px',
    backgroundColor: `${({ theme }) =>
      theme.dark ? GREYS.silverD : GREYS.white}`,
    color: `${({ theme }) => (theme.dark ? GREYS.white : GREYS.black)}`,
  },
};
export const MD = styled.div`
  display: flex;
  justify-content: space-between;
  height: 50px;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
  font-size: 20px;
  text-transform: uppercase;
  margin-top: -20px;
`;

export const QH6 = styled.h6`
  font-size: 12px;
  color: ${GREYS.doveD};
  margin-bottom: 8px;
  font-weight: 400;
`;
export const P1H4 = styled.h4`
  font-size: 14px;
  display: flex;
  align-items: center;
  color: ${FONT.dark};
  align-items: flex-end;
`;
export const P1IMG = styled.img`
  width: 38px;
  margin-right: 8px;
`;
