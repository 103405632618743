import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'CORE__UI/globals/Modal/core__modal'; //caution: don't use local import
import PubSub from 'Services/pubsub/core__pubsub';
import { PubsubEvents } from 'Services/pubsub/core__pubsub.constants';
import ProductRestriction from 'Services/productRestriction/core__product-restriction';
import {
  MStyles,
  MD,
  GreenIcon as GreenIcon_LOCAL,
  Total as Total_LOCAL,
  IC as IC_LOCAL,
} from 'UI/apps/QuickDepositApp/QuickDeposit';
import { Translations } from '../../../core__quick-deposit-app';
import { GLOBAL_PATH } from 'Services/global/core__constants';
import { CloseIcon } from 'UI/globals/ModalCloseIcon';
import {
  createCookie,
  removeCookie,
  GLOBAL_CONSTANTS,
} from 'Services/core__services';
import {
  GreenIcon as GreenIcon_CORE,
  Total as Total_CORE,
  InfoText,
  IC as IC_CORE,
} from 'CORE__UI/apps/BankingApp/core__successful-withdraw-modal';
import ModalButton from 'UI/buttons/ModalButton';
import { CurrencyFormat } from 'Services/global/core__currency-format';
import { getComponent } from 'Services/core__imports';
const GreenIcon = getComponent(GreenIcon_LOCAL, GreenIcon_CORE);
const Total = getComponent(Total_LOCAL, Total_CORE);
const IC = getComponent(IC_LOCAL, IC_CORE);

/**
 * Help App
 * @param {array} helpData
 */

const QuickDeposit = ({ showdepositcompleted }) => {
  const [open, setOpen] = useState(false);
  const [missingAmt, setMissingAmt] = useState('');
  const [deposited, setDeposited] = useState(false);
  const [depositAmt, setDepositAmt] = useState('');
  const [depositCurr, setDepositCurr] = useState('');
  const onCloseModal = () => {
    setOpen(false);
    removeCookie('quickDeposit');
    setMissingAmt('');
  };

  const onCloseDepositedModal = () => {
    setDeposited(false);
  };

  useEffect(() => {
    const opeQdListener = PubSub.listen(PubsubEvents.OPE_QD, wsData => {
      if (wsData && !ProductRestriction?.getIsAllProductRestricted(true)) {
        setOpen(true);
        createCookie('quickDeposit', true);
        setMissingAmt(Number(wsData.fundReq).toFixed(2));
      } else if (wsData === null) {
        setOpen(true);
        createCookie('quickDeposit', true);
        setMissingAmt('0');
      }
    });
    return () => {
      opeQdListener?.unsubscribe();
    };
  }, [open]);

  //to listening from the iframe pages - closing quick deposit on the completion of a deposit
  useEffect(() => {
    window.addEventListener('message', msg => {
      if (msg.data.paymentData) {
        onCloseModal();
        PubSub.emit(PubsubEvents.CallBetSlip, { place: true });
        setDepositAmt(msg?.data?.paymentData?.payment[0].amount);
        setDepositCurr(
          CurrencyFormat(msg?.data?.paymentData?.payment[0].currencyCode)
        );
        setDeposited(
          msg?.data?.paymentData?.payment[0].state === GLOBAL_CONSTANTS.APPROVED
        );
      }
    });
  }, []);

  return open ? (
    <Modal
      open={open}
      styles={MStyles}
      closeOnEsc={true}
      closeOnOverlayClick={true}
      onEscKeyDown={onCloseModal}
      onOverlayClick={onCloseModal}
      onClose={onCloseModal}
      closeIcon={CloseIcon()}
      cantEscape={false}
    >
      <MD>
        <span>{Translations.get('text.quick.deposit')}</span>
      </MD>
      <IC>
        <iframe
          id="quickDeposit"
          sandbox="allow-same-origin allow-scripts allow-forms allow-popup allow-modals allow-popups-to-escape-sandbox"
          src={GLOBAL_PATH.QUICK_DEPOSIT + `${missingAmt}`}
        ></iframe>
      </IC>
    </Modal>
  ) : (
    (deposited && showdepositcompleted && (
      <Modal
        open={deposited}
        styles={MStyles}
        closeOnEsc={true}
        closeOnOverlayClick={true}
        onEscKeyDown={onCloseDepositedModal}
        onOverlayClick={onCloseDepositedModal}
        onClose={onCloseDepositedModal}
        closeIcon={CloseIcon()}
        cantEscape={false}
      >
        <GreenIcon className="icon-Tickmark-in-Circle" />
        <Total>
          {depositCurr}
          {depositAmt}
        </Total>
        <InfoText>{Translations.get('text.deposit.text')}</InfoText>
        <ModalButton
          onClick={onCloseDepositedModal}
          label={Translations.get('text.close')}
        />
      </Modal>
    )) ||
      ''
  );
};

QuickDeposit.propTypes = {
  showdepositcompleted: PropTypes.bool,
};

export { QuickDeposit };
