import React from 'react';
import { QuickDeposit } from './components/QuickDeposit';
import PropTypes from 'prop-types';
import { FSBTheme } from 'Services/core__fsb-theme';

/**
 * Help App
 * @param {object} appConfig
 */

class QuickDepositApp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      render: false,
    };
  }

  componentDidMount() {
    this.setState({ render: true });
  }

  render() {
    if (!this.state.render) {
      return <React.Fragment />;
    }
    const { providers, theme, showdepositcompleted } = this.props.appConfig;
    return (
      <FSBTheme theme={theme}>
        <QuickDeposit
          providers={providers}
          showdepositcompleted={showdepositcompleted}
        />
      </FSBTheme>
    );
  }
}

export default QuickDepositApp;

QuickDepositApp.propTypes = {
  appConfig: PropTypes.object,
};
