import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import getComposeEnhancers from 'Services/redux/core__redux-dev-tools';
import { TranslationsProvider } from 'Services/translations/core__translations';

export const Translations = new TranslationsProvider('QuickDepositApp');

export const dataReducer = (state = []) => {
  return state;
};

//Combine reducers into a single reducer
export const reducer = combineReducers({
  data: dataReducer,
});

const composeEnhancers = getComposeEnhancers({ name: 'quick-deposit-store' });

export default initialState =>
  createStore(
    reducer,
    initialState,
    composeEnhancers(applyMiddleware(thunkMiddleware))
  );
